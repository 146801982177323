@import "./../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "./theme.scss";

html {
  font-size: 0.9em !important;
}
.bg-gray {
  background-color: $gray-700 !important;
}
.text-gray {
  color: $gray-700 !important;
}
.card {
  box-shadow: $box-shadow;
  overflow: hidden;
}
.transition-base {
  transition: $transition-base !important;
}

.bg-size-cover {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.overthrow {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.cursor-pointer {
  cursor: pointer !important;
}
